import { FlexGrowBox } from "./common.styles";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { McButton } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";
import Box from "./box";

/**
 * `TitleBox` is a React functional component that renders a customizable title box with optional features.
 * It supports expansion, editing, deletion, and creation actions, with the ability to load content on expansion.
 *
 * Props:
 * - `id`: any - Identifier for the title box, required for handling actions.
 * - `title`: string - The title text to be displayed in the title box.
 * - `keyName`: string (optional) - A key name associated with the title box, default is an empty string.
 * - `shouldHandleExpansion`: boolean (optional) - If true, the title box can be expanded or collapsed, default is false.
 * - `shouldLoadOnExpansion`: boolean (optional) - If true, content will be loaded when the title box is expanded, default is false.
 * - `shouldShowButtons`: boolean - Controls the visibility of action buttons (edit, delete, create) in the title box.
 * - `shouldShowEditButton`: boolean - If true, an edit button is displayed.
 * - `shouldShowDeleteButton`: boolean - If true, a delete button is displayed.
 * - `editLabel`: string (optional) - Label for the edit button, default is "Edit".
 * - `deleteLabel`: string (optional) - Label for the delete button, default is "Delete".
 * - `label`: string (optional) - Label for a generic action or create button, default is "Create".
 * - `onExpand`: function (optional) - Callback function triggered on expanding the title box.
 * - `onEdit`: function (optional) - Callback function triggered on clicking the edit button.
 * - `onDelete`: function (optional) - Callback function triggered on clicking the delete button.
 * - `onCreate`: function (optional) - Callback function triggered on clicking the create button.
 * - `children`: ReactNode (optional) - Children components to be rendered inside the title box, typically used for content.
 *
 * Usage:
 * <TitleBox
 *   id={uniqueId}
 *   title="Sample Title"
 *   shouldShowButtons={true}
 *   shouldShowEditButton={true}
 *   shouldShowDeleteButton={true}
 *   onEdit={() => console.log("Edit action")}
 *   onDelete={() => console.log("Delete action")}
 *   onCreate={() => console.log("Create action")}
 * >
 *   <p>Content goes here</p>
 * </TitleBox>
 *
 * Note: This component utilizes styled-components for styling and includes a dependency on `@maersk-global/mds-react-wrapper` for the `McButton` component.
 */
export default function TitleBox({
  id,
  keyName = "",
  title,
  editLabel = "Edit",
  deleteLabel = "Delete",
  label = "Create",
  shouldShowButtons = false,
  shouldShowEditButton = false,
  shouldShowDeleteButton = false,
  shouldHandleExpansion = false,
  shouldLoadOnExpansion = false,
  onExpand,
  onEdit,
  onDelete,
  onCreate,
  children,
  renderComponent,
}: Readonly<{
  id?: any;
  title: string | React.ReactNode;
  keyName?: string;
  shouldHandleExpansion?: boolean;
  shouldLoadOnExpansion?: boolean;
  shouldShowButtons?: boolean;
  shouldShowEditButton?: boolean;
  shouldShowDeleteButton?: boolean;
  editLabel?: string;
  deleteLabel?: string;
  label?: string;
  onExpand?: (keyName: string, id: any) => void;
  onEdit?: (id: any) => void;
  onDelete?: (id: any) => void;
  onCreate?: (id: any) => void;
  children?: any;
  renderComponent?: any;
}>) {
  const [expanded, setExpanded] = useState(false);
  const handleAccordianExpansion = () => {
    setExpanded(!expanded);

    if (shouldLoadOnExpansion && !expanded && onExpand) {
      onExpand(keyName, id);
    }
  };
  return (
    <>
      <TitleBoxWrapper>
        <Box>
          <div style={{ display: "flex" }}>
          <Box display="flex" alignItems="center" mr={2}>
            <Box ln={1}>
              <span className="titleBoxHeading">{title}</span>
              </Box>
              <Box ml={10}>
              {renderComponent ? renderComponent : null}
              </Box>
            </Box>
            {shouldHandleExpansion && (
              <StaticIcon
                icon={expanded ? "chevron-up" : "chevron-down"}
                onClick={() => handleAccordianExpansion()}
              />
            )}
          </div>
        </Box>
        <FlexGrowBox />
        <Box display="flex" className="rightSideWrapper">
          {shouldShowButtons ? (
            <>
              {
                <>
                  {shouldShowEditButton && (
                    <McButton
                      appearance="neutral"
                      id={`Edit_${id}`}
                      justifyitems="center"
                      label={editLabel}
                      name={editLabel}
                      click={() => onEdit && onEdit(id)}
                      title={label}
                      variant="filled"
                      fit="small"
                    />
                  )}
                  {shouldShowDeleteButton && (
                    <McButton
                      appearance="neutral"
                      id={`Delete_${id}`}
                      justifyitems="center"
                      label={deleteLabel}
                      name={deleteLabel}
                      click={() => onDelete && onDelete(id)}
                      title={label}
                      variant="filled"
                      fit="small"
                    />
                  )}
                </>
              }
              <McButton
                appearance="secondary"
                id={`Create_${id}`}
                justifyitems="center"
                label={label}
                name={label}
                click={() => onCreate && onCreate(id)}
                title={label}
                variant="filled"
                fit="small"
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </TitleBoxWrapper>
      {shouldHandleExpansion && expanded && children}
    </>
  );
}

export const TitleBoxWrapper = styled(Box)`
  display: flex;
  background-color: ${colors["mds_core_background_primary_weak_color"]};
  padding: 16px 16px 16px 16px;

  .titleBoxHeading {
    font-weight: bold;
  }
  .actionButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 11.67px;
  }
  .rightSideWrapper {
    gap: 8px;
  }
`;
