import React from "react";

interface Info {
  [key: string]: string | React.ReactNode;
}

export const BrandsInfo: Info = {
  Page_Subheading: (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <ul>
          <li>It lists all the business brands in the system. </li>
          <li> Configure business brands.</li>
          <li> Configure sitetypes and archetypes under a business brand.</li>
        </ul>
      </div>
    </div>
  ),
  AddEditBrand_BrandName:
    "Business Brand name, alphanumeric, max upto 100 characters.",
  AddEditBrand_SiteType: "Sitetype name, alphanumeric, max upto 50 characters.",
  AddEditBrand_Archetype:
    "Archetype name, alphanumeric, max upto 50 characters.",
  AddEditRegion_RegionName:
    "Enter a Region Name under the brand, alphanumeric, max upto 50 characters",
  AddEditRegion_RegionCountry: (
    <>
      Type country name of the box to search and select. <br />
      Any number of countries can be selected from the list.
    </>
  ),
  AddEditRegion_AreaName:
    "Area Name under the region, alphanumeric, max upto 50 characters.",
  AddEditRegion_AreaCountry: (
    <>
      List of countries for an area <br />
      <strong>Note: </strong> All the Countries selected for a region will be
      listed here.
    </>
  ),
};
export const UserInfo: Info = {
  Page_Subheading: (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <ul>
          <li>Displays all the users in the system. </li>
          <li>Add one or more users here.</li>
          <li>User can be a office user or a site user.</li>
          <li> Manage user roles here.</li>
          <li> One user can be mapped to one role.</li>
          <li> Assign one or more sites to a user.</li>
          <li>
            First a user needs to be added with a role and then site needs to be
            assigned for a user by <br />
            clicking on plus(+) icon under assigned sites column.
          </li>
        </ul>
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <strong>Note: </strong> First we add user in our azure all users group.
        Then user can be <br />
        searched here and added to the system by clicking on Add new user
        button.
      </div>
    </div>
  ),
  AddEditUserModal_Name: (
    <>
      Search by typing name or email. User should be available in the azure
      group.
      <br />
      On selecting, Job Title, Email and Business Phone will be auto populated.
    </>
  ),
  AddEditUserModal_Phone: " Phone Number of the User from Active Directory",
  AddEditUserModal_IsActive:
    "Set user as active or inactive. A user cannot be added as not active hence active is disabled on addition.",
  AddEditUserModal_Role: (
    <>
      Select a role for the user. <br />
      Values appearing here depends on the type of user selected.{" "}
    </>
  ),
  AddEditUserModal_IncidentResponseTeam:
    "Selecting will make the user a part of Incident Response Team.",
};
export const CustomerInfo: Info = {
  Page_Subheading: (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <ul>
          <li>Displays all the global customers in the system. </li>
          <li>Add one or more customers here.</li>
          <li> Assign one or more sites to a user.</li>
          <li>
            First a customer needs to be added and then site needs to be
            assigned for a user by <br />
            clicking on plus(+) icon under assigned sites column.
          </li>
        </ul>
      </div>
    </div>
  ),
  AddEditCustomer_Name: (
    <>Customer name, alphanumeric, max upto 200 characters</>
  ),
  AddEditCustomer_Service: (
    <>Customer service, alphanumeric, max upto 200 characters</>
  ),
  AddEditCustomer_ConcerCode: (
    <>
      Concern code, alphanumeric, max upto 200 characters. This is unique for
      across all customers.
    </>
  ),
  //TODO: SSS
  AddEditCustomer_TypeOfCustomer: <>Describe: Key client and runaway client</>,
  AddEditCustomer_Vertical: "Select a vertical to which a customer belongs to.",
  AddEditCustomer_KeyClientDirector:
    "Key Client Director details of the Customer",
  AddEditCustomer_CPD: "Client Program Director of the Customer",
  AddEditCustomer_CPM: "Client Program Manager of the Customer",
  AddEditCustomer_SCD: "Supply Chain Director of the Customer",
  AddEditCustomer_BCPRequirement: "BCP  is required for the Customer or not.",
  AddEditCustomer_BCPRequirementComment:
    "BCP Requirement comment is mandatory when BCP Requirement is Yes.",
  AddEditCustomer_BCPTestRequirement: "BCP Test Requirement",
  AddEditCustomer_BCPTestRequirementComment:
    "BCP Requirement comment is mandatory when BCP Requirement is Yes.",
  AddEditCustomer_ITRecoveryRequirement: "IT Recovery Requirement.",
  AddEditCustomer_ITRecoveryRequirementComment:
    "IT Recovery Requirement comment is mandatory when IT Recovery Requirement is Yes.",
  AddEditCustomer_BCTolerance: "BC Tolerance Value",
  AddEditCustomer_BCToleranceTimeFrame:
    "BC Tolerance Timeframe in Hours/Days/Weeks.",
  AddEditCustomer_IncidentNotification: "Incident Notification",
  AddEditCustomer_IncidentNotificationTimeframe:
    "Incident Notification Timeframe  in Hours/Days/Weeks",
  AddEditCustomer_IncidentNotificationMethod: "Incident Notification Method",
  AddEditCustomer_OtherBCRequirements: "Other BC Requirements",
  AddEditCustomer_OperationData: "Operation Data",
  AddEditCustomer_AvgWeeklyInboundOutboundVolume:
    "Average weekly inbound outbound volume",
  AddEditCustomer_AvgWeeklyVesselCalls: "Average Weekly Vessel calls",
  AddEditCustomer_CommitmentService: "Commitment Service",
  AddEditCustomer_MinBusinessContinuityLevel:
    "Minimum Business Continuity Level",
  AddEditCustomer_ContractualLiabilityFailure: "ContractualLiabilityFailure",
};
export const SiteMappingInfo = {
  SiteMappingInfo_Brand: "Lists all the Brands in the System.",
  SiteMappingInfo_SiteType: "Lists all Sitetype for the selected brand.",
  SiteMappingInfo_Archetype: "Lists all archetype for the selected sitetype.",
  SiteMappingInfo_Region: "Lists all regions for the selected archetype.",
};
export const SystemConfiguration: Info = {
  Page_Subheading: (
    <>
      Site Configuration Settings will be applicable for all the Sites under the
      selected Arche Type.
      <br />
      Configuration value for the Attributes will be Applicable under the
      respective section of all Sites in this Arche Type.
    </>
  ),
};
export const SystemConfigurationSetup = {
  AddEditDependencies_DependencyCategory: (
    <>
      Dependency Category to Create or Edit for the Arche Type.
      <br />
      Dependency Category will be available for all the Sites under the Arche
      Type."
    </>
  ),
  AddEditDependencies_Dependency:
    "Dependency to Create or Edit for the Dependency Category.",
  AddEditServiceModal_Service: "Name of the Service to Create or Edit.",
  AddEditServiceModal_Configuration: (
    <>
      Configuration Values for the Service.
      <br />
      This Value will be applicable for the Service for all the Sites under the
      Arche Type.
    </>
  ),
  AddEditSiteModal_GroupID: "Group ID is Autgenerated from GRIT System.",
  AddEditSiteModal_SiteID: "Unique Identifier for the Site.",
  AddEditSiteModal_GroupName:
    "Group Name for the Site.Multiple Sites can be grouped under the same Group.",
  AddEditSiteModal_SiteName: "Unique Name for the Site.",
  AddEditSiteModal_Region: "Region where the Site is Located.",
  AddEditSiteModal_Area: "Area where the Site is Located.",
  AddEditSiteModal_Country: "Country from the Selected Area.",
  AddEditSiteModal_Address: "Address of the Site",
  AddEditSiteModal_PostNumber: "Post Number of the Site",
  AddEditSiteModal_City: "City where the Site is Located",
  AddEditSiteModal_Entity: "Entity Details of the Site",
  AddEditGroupSiteModal_SiteID: "Unique Identifier for the Site.",
  AddEditGroupSiteModal_SiteName: "Unique Name for the Site.",
  AddEditGroupSiteModal_Address: "Address of the Group",
  AddEditGroupSiteModal_PostNumber: "Post Number of the Group",
  AddEditGroupSiteModal_City: "City where the Group is Located",
  AddEditGroupSiteModal_Entity: "Entity Details of the Group",
};
export const ProcessConfigurationSetup = {
  AddEditProcessModal_ProcessName: "Name of the Process to Create or Edit.",
  AddEditProcessModal_Subprocess: "Name of the Subprocess to Create or Edit.",
  SaveContinuityPlan_Dependency:
    "Dependency Name to which Continuity Plan is Associated.",
};
