import styled from "styled-components";
import Box from "../common/box";

export const AppContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header  header"
    "sidebar content";
  width: 100vw;
`;

export const HeaderContainer = styled.div`
  grid-area: header;
  width: 100vw;
  z-index: 90;
  position: fixed;
  right: 0;
`;

export const SidebarContainer = styled(Box)`
  grid-area: sidebar;
  z-index: 95;
  top: 0;
`;

export const Content = styled.main`
  grid-area: content;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "pagecontent";
  height: 100%;
  margin-top: 80px;
  overflow: auto;
`;

export const PageContainer = styled.section`
  grid-area: pagecontent;
  padding: 30px;
  overflow: auto;
`;
