export const getApplicationConfig = () => (window as any).config;

export const getApiUrl = () => {
  let appConfig = getApplicationConfig();
  return `${appConfig.apiResource.baseAddress}/api/${appConfig.apiResource.apiVersion}`;
};

export const chunkArray = (array: any[], size: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const trimStringsBeforeSave = (obj: any) => {
  const trimmedObj: any = {};
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }
  return trimmedObj;
};
