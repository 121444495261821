import { colors } from "../../styles/colors";
import React from "react";

const McIcon = React.lazy(() => import("@maersk-global/mds-react-wrapper").then((module) => ({ default: module.McIcon })));

const Icon = (props: any) => {
  return <McIcon {...props} />;
};

export const DeleteIcon = (props: any) => (
  <Icon
    icon="trash"
    color={`${colors["mds_brand_appearance_error_default_text-color"]}`}
    className={"pointer"}
    {...props}
  />
);

export const EditIcon = (props: any) => <Icon icon="pencil" {...props} />;

export const StaticIcon = (props: any) => <Icon {...props} />;
