import { useEffect, useReducer } from "react";
import { toast } from "../../../components/common/toast";
import {
  errorReducer,
  defaultError,
  errorActions,
} from "../../../reducers/error";
import { brandReducer, defaultBrand, brandActions } from "../reducers/brand";
import { createOrUpdateBrand } from "../../../services/Brands";
import CommonModal, {
  AddNewContentText,
  ExpandableContentDeleteButtonWrapper,
  ExpandableContentWrapper,
} from "../../../components/common/commonModal";
import { ArcheType, Brand, SiteType } from "../../../models/Brand";
import { DeleteIcon, StaticIcon } from "../../../components/common/icons";
import Tooltip from "../../../components/common/tooltip";
import { colors } from "../../../styles/colors";
import { McInput } from "@maersk-global/mds-react-wrapper";
import Box from "../../../components/common/box";
import Info from "../../../components/common/info";
import { BrandsInfo } from "../../../constants/Info";

const errorMessagesValidation = {
  BRAND_NAME_IS_REQUIRED: "Brand name is required.",
  SITETYPE_NAME_IS_REQUIRED: "Site type name is required.",
  ARCHETYPE_NAME_IS_REQUIRED:
    "Archetype name is required when adding a site type.",
};

export default function AddEditBrandModal(props: any) {
  const [brand, dispatch] = useReducer(brandReducer, defaultBrand);
  const [errors, dispatchErrors] = useReducer(errorReducer, defaultError);

  useEffect(() => {
    dispatch({
      type: brandActions.SET_EDITABLE_BRAND,
      brand: props.editableBrand,
    });
  }, [props.isOpen, props.editableBrand]);

  const onModalClose = () => {
    dispatchErrors({ type: errorActions.REMOVE_ERROR });
    props.onClose();
  };

  const addOrUpdateBrand = async () => {
    if (!validateBrand()) return;
    let restructuredBrand: Brand = restructureBrand();

    createOrUpdateBrand(restructuredBrand)
      .then((response) => {
        toast(
          "Success",
          `Brand 
        ${restructuredBrand.id === 0 ? "added" : "updated"} successfully`,
          "success"
        );
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        dispatchErrors({
          type: errorActions.ADD_ERROR,
          errorMessage: [error.message],
        });
      });
  };

  const addSiteType = () =>
    dispatch({
      type: brandActions.ADD_SITE_TYPE,
    });

  const addArchetype = (siteTypeIndex: number) =>
    dispatch({
      type: brandActions.ADD_ARCHETYPE,
      siteTypeIndex: siteTypeIndex,
    });

  const removeSiteType = (siteTypeIndex: number) => {
    dispatch({
      type: brandActions.REMOVE_SITE_TYPE,
      siteTypeIndex: siteTypeIndex,
    });
  };
  const removeArchetype = (siteTypeIndex: number, archeTypeIndex: number) => {
    dispatch({
      type: brandActions.REMOVE_ARCHETYPE,
      archeTypeIndex: archeTypeIndex,
      siteTypeIndex: siteTypeIndex,
    });
  };

  const restructureBrand = (): Brand => {
    const brandData = {
      id: brand.id,
      businessBrandName: brand.businessBrandName.trim(),
      siteTypes: brand.siteTypes.map((siteType: SiteType) => {
        return {
          id: siteType.id,
          siteTypeName: siteType.siteTypeName.trim(),
          archeTypes: siteType.archeTypes.map((archeType: ArcheType) => {
            return {
              id: archeType.id,
              archeTypeName: archeType.archeTypeName.trim(),
            };
          }),
        };
      }),
    };

    return brandData;
  };

  const validateBrand = () => {
    const errorMessages: string[] = [];

    if (
      brand.businessBrandName.trim() === "" &&
      !errorMessages.includes(errorMessagesValidation.BRAND_NAME_IS_REQUIRED)
    ) {
      errorMessages.push(errorMessagesValidation.BRAND_NAME_IS_REQUIRED);
    }

    brand.siteTypes.forEach((siteType: SiteType, siteTypeIndex: number) => {
      if (
        siteType.siteTypeName.trim() === "" &&
        !errorMessages.includes(
          errorMessagesValidation.SITETYPE_NAME_IS_REQUIRED
        )
      ) {
        errorMessages.push(errorMessagesValidation.SITETYPE_NAME_IS_REQUIRED);
      }
      siteType.archeTypes.forEach(
        (archeType: ArcheType, archeTypeIndex: number) => {
          if (
            archeType.archeTypeName.trim() === "" &&
            !errorMessages.includes(
              errorMessagesValidation.ARCHETYPE_NAME_IS_REQUIRED
            )
          ) {
            errorMessages.push(
              errorMessagesValidation.ARCHETYPE_NAME_IS_REQUIRED
            );
          }
        }
      );
    });

    if (errorMessages.length > 0) {
      dispatchErrors({
        type: errorActions.ADD_ERROR,
        errorMessage: errorMessages,
      });
      return false;
    }

    return true;
  };

  return (
    <CommonModal
      primaryActionLabel="Confirm"
      primaryAction={addOrUpdateBrand}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={onModalClose}
      isOpen={props.isOpen}
      onModalClose={onModalClose}
      heading={`${
        props.editableBrand.id === 0 ? "Add new" : "Edit"
      } business brand`}
      dimension="medium"
      errors={errors}
      height={"600px"}
    >
      <McInput
        fit="medium"
        id="brandName"
        label="Brand name"
        input={(e: any) => {
          dispatch({
            type: brandActions.BRAND_NAME_CHANGE,
            businessBrandName: e.target.value,
          });
          dispatchErrors({ type: errorActions.REMOVE_ERROR });
        }}
        placeholder=""
        required
        type="text"
        maxlength={100}
        value={brand.businessBrandName}
        variant="default"
      >
        <Info
          popoverContent={BrandsInfo.AddEditBrand_BrandName}
          tooltipText="Brand Name"
        />
      </McInput>
      {brand.siteTypes.map((siteType: SiteType, siteTypeIndex: number) => (
        <ExpandableContentWrapper key={siteType.id}>
          {siteType.id === 0 && (
            <Box
              display="flex"
              gridTemplateColumns="1fr"
              gridGap={3}
              style={{ justifyContent: "flex-end" }}
            >
              <Tooltip content="Remove sitetype" position="left-top">
                <DeleteIcon
                  onClick={() => removeSiteType(siteTypeIndex)}
                  slot="trigger"
                />
              </Tooltip>
            </Box>
          )}
          <Box display="grid" gridTemplateColumns="20fr 1fr" gridGap={3}>
            <McInput
              fit="small"
              id={siteType.id.toString()}
              label="Site type"
              maxlength={50}
              input={(e: any) => {
                dispatch({
                  type: brandActions.SITE_TYPE_NAME_CHANGE,
                  siteTypeName: e.target.value,
                  index: siteTypeIndex,
                });
              }}
              placeholder=""
              required
              type="text"
              value={siteType.siteTypeName}
              variant="default"
            >
              <Info
                popoverContent={BrandsInfo.AddEditBrand_SiteType}
                tooltipText="SiteType"
              />
            </McInput>
          </Box>
          {siteType.archeTypes.map(
            (archeType: ArcheType, archeTypeIndex: number) => (
              <Box
                display="grid"
                gridTemplateColumns="20fr 1fr"
                gridGap={3}
                key={archeType.id}
              >
                <Box p={3}>
                  <McInput
                    fit="small"
                    id={archeType.id.toString()}
                    label="Archetype"
                    maxlength={50}
                    input={(e: any) => {
                      dispatch({
                        type: brandActions.ARCHETYPE_NAME_CHANGE,
                        archeTypeName: e.target.value,
                        siteTypeIndex: siteTypeIndex,
                        archeTypeIndex: archeTypeIndex,
                      });
                    }}
                    placeholder=""
                    required
                    type="text"
                    value={archeType.archeTypeName}
                    variant="default"
                    key={siteType.id + "-" + archeType.id}
                  >
                    <Info
                      tooltipText="Archetype"
                      popoverContent={BrandsInfo.AddEditBrand_Archetype}
                    />
                  </McInput>
                </Box>
                <ExpandableContentDeleteButtonWrapper>
                  {archeType.id === 0 && archeTypeIndex !== 0 && (
                    <Tooltip content="Remove archetype" position="left-top">
                      <DeleteIcon
                        onClick={() =>
                          removeArchetype(siteTypeIndex, archeTypeIndex)
                        }
                        slot="trigger"
                      />
                    </Tooltip>
                  )}
                </ExpandableContentDeleteButtonWrapper>
              </Box>
            )
          )}
          <AddNewContentText onClick={() => addArchetype(siteTypeIndex)}>
            <StaticIcon
              icon="plus"
              color={`${colors["mds_core_button_primary_filled_background-color"]}`}
            />
            <span>Add new</span>
          </AddNewContentText>
        </ExpandableContentWrapper>
      ))}
      <AddNewContentText onClick={() => addSiteType()}>
        <StaticIcon
          icon="plus"
          color={`${colors["mds_core_button_primary_filled_background-color"]}`}
        />
        <span>Add new site type</span>
      </AddNewContentText>
    </CommonModal>
  );
}
