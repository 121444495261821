import styled from "styled-components";
import { colors } from "../../styles/colors";
import { maerskHeadlineFontFamily } from "../../styles/fonts";
import React from "react";

/**
 * `heading` is a React functional component that renders a heading section with an optional subheading and additional children components.
 * It is designed to display a primary heading text alongside a subheading, with an option to include additional React nodes or components to the right of the heading and subheading.
 *
 * Props:
 * - `heading`: string - The main text to be displayed as the heading.
 * - `subheading`: React.ReactNode | string - The text or component to be displayed as the subheading beneath the main heading.
 * - `children`: React.ReactNode (optional) - Additional components or elements to be displayed to the right of the heading and subheading.
 *
 * The component utilizes styled-components for styling, with specific styles applied to the heading, subheading, and the container for additional children.
 * The `HeadingContainer` styled component is used to layout the heading and subheading alongside the children, using flexbox to align items and distribute space.
 *
 * Usage Example:
 * ```tsx
 * <heading
 *   heading="Main Heading"
 *   subheading={<span>Subheading Content</span>}
 * >
 *   <button>Action</button>
 * </heading>
 * ```
 *
 * Note: The component imports and uses colors from `../../styles/colors` and font settings from `../../styles/fonts` to style the heading and subheading.
 */
export default function heading({
  heading,
  subheading,
  children,
}: {
  heading: string;
  subheading: React.ReactNode | string;
  children?: React.ReactNode;
}) {
  return (
    <HeadingContainer>
      <div className="headingWrapper">
        <div className="heading">{heading}</div>
        <div className="sub-heading">{subheading}</div>
      </div>
      <div className="right-wrapper">{children}</div>
    </HeadingContainer>
  );
}

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headingWrapper {
        margin-bottom: 20px;
    }
    .heading {
        color: ${colors["mds_brand_appearance_primary_default_text-color"]};
        font-family: ${maerskHeadlineFontFamily};
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        gap: 4px;
    }

    .sub-heading {
        font-family: ${maerskHeadlineFontFamily};
        font-size: 18px,
        font-style: normal;
        font-weight: 200;
        line-height: 24px;
        color: ${colors["mds_brand_appearance_primary_default_text-color"]};
        margin-top: 8px;
    }

    .right-wrapper {
      display: flex;
      gap: 8px;
    }
`;